import React from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const LicitacionesDetalle = () => {
  return (
    <div className="col-lg-8">
      <div className="service_details_items">
        <div className="service_details_para"></div>
        <h3>Licitaciones</h3>
        <p>Consulta en este espacio las licitaciones públicas vigentes</p>
         <Card>
          <Card.Body>
            <Card.Title>LP/MLC/RP2024/0005</Card.Title>
            <Card.Text>
              <p>
                Arrendamiento Puro de 8 Vehículos Terrestres con GPS para Ejecución de programas de Recolección de Basura
              </p>
              <p>Periodo de Vigencia: 31/10/2024 al 06/11/2024</p>
              <p>Junta de Aclaraciones: 08/11/2024 02:00 PM</p>
              <p>
                Presentación y Apertura de Propuestas Técnicas y Económicas:
                14/11/2024 12:00 IM
              </p>
              <p>Fallo de Adjudicación: 14/09/2024 12:30 IM</p>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Link
            role="button"
              to={{
                pathname:
                  "https://lazarocardenas.blob.core.windows.net/publico/licitaciones/CONVOCATORIA 8 CAMIONES LICITACION 0005.pdf",
              }}
              target="_blank"
            >
              Convocatoria
            </Link>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>LP/MLC/RP2024/0004</Card.Title>
            <Card.Text>
              <p>
               Arrendamiento puro de 8 camiones recolectores de basura
              </p>
              <p>Periodo de Vigencia: 21/10/2024 al 25/10/2024 de 09:00 a 15:00</p>
              <p>Junta de Aclraciones: 28/10/2024 11:00 AM</p>
              <p>
                Presentación y Apertura de Propuestas Técnicas y Económicas:
                04/11/2024 11:00 AM
              </p>
              <p>Fallo de Adjudicación: 05/11/2024 11:00 AM</p>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Link
            role="button"
              to={{
                pathname:
                  "https://lazarocardenas.blob.core.windows.net/publico/licitaciones/H. AYUNTAMIENTO CONSTITUCIONAL DE LÁZARO, CÁRDENAS, MICHOACÁN..pdf",
              }}
              target="_blank"
            >
              Convocatoria
            </Link>
          </Card.Footer>
        </Card>
        <br />
        <Card>
          <Card.Body>
            <Card.Title>LP/MLC/RP-FIV2024/0003</Card.Title>
            <Card.Text>
              <p>
                Servicio de suministro de combustible (Gasolina y Diesel), de la
                Administración Pública Municipal de Lázaro Cárdenas, Michoacán.
              </p>
              <p>Periodo de Vigencia: 11/09/2024 al 17/09/2024</p>
              <p>Junta de Aclraciones: 19/09/2024 11:00 AM</p>
              <p>
                Presentación y Apertura de Propuestas Técnicas y Económicas:
                19/09/2024 11:00 AM
              </p>
              <p>Fallo de Adjudicación: 26/09/2024 12:00 AM</p>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Link
            role="button"
              to={{
                pathname:
                  "https://lazarocardenas.blob.core.windows.net/publico/licitaciones/CONVOCATORIA GASOLINA Y DIESEL 2024 SEP.pdf",
              }}
              target="_blank"
            >
              Convocatoria
            </Link>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};
export default LicitacionesDetalle;
